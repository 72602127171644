import React, { useEffect, useState } from 'react';
import quizQuestionService from '../../services/QuizQuestionService';
import './index.scss';
import { Divider, List, ListItem, ListItemAvatar, Avatar, Button, Modal, Box, TextField } from '@mui/material';
import swal from 'sweetalert';
import { IComment, IQuestion } from '../../interfaces/IQuestion';
import { useNavigate } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import Answers from './answer';
import { IconButton } from '@material-ui/core';
import { CommentsDisabled } from '@mui/icons-material';
import lessonService from '../../services/LessonService';

export interface Course {
  id: number;
  name: string;
}

export interface Lesson {
  id: number;
  name: string;
  course: Course;
}

export interface Question {
  id: string;
  title: string;
  text: string;
  lesson: Lesson;
  user: User;

  comments?: IComment[];
}

export interface User {
  name: string;
}

interface QuestionProps {
  question: Question;
  onRespond: () => void;
}

const QuestionDetails: React.FC<QuestionProps> = ({
  question,
  onRespond,
}) => {
  const [areAnswersVisible, setAreAnswersVisible] = useState(false);

  const toggleAnswersVisibility = () => {
    setAreAnswersVisible(!areAnswersVisible);
  };

  return (
    <List className="forum">
      <ListItem>
        <div
          className="duvidas-students"
          style={{
            border: '1px solid rgba(0, 0, 0, 0.1)',
            padding: '10px 10px',
            borderRadius: '10px',
            marginLeft: '25%',
            width: '50%',
            background: '#ffffff',
          }}
        >
          {/* Curso e Aula */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '35px',
            }}
          >
            <p style={{ margin: 0 }}>
              <strong>Curso:</strong> {question.lesson.course.name}
            </p>
            <p style={{ margin: '0 0 0 10px' }}>
              <strong>Aula:</strong> {question.lesson.name}
            </p>
          </div>


           <div className="pergunta"           
           style={{
            border: "1px solid rgba(0, 0, 0, 0.1)",
            padding: "10px 10px",
            borderRadius: "10px",
            width: "96%",
            background: "#e5e7eb",
          }}
           
           >
          {/* Avatar e Nome do Usuário */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            <ListItemAvatar>
              <Avatar>{/* Ícone ou imagem */}</Avatar>
            </ListItemAvatar>
            <div>
              <div className="subtitle">{question.user.name}</div>
            </div>
          </div>

          {/* Texto da Pergunta */}
          <div
            className="p_normal"
            style={{
              width: '100%',
              height: 'auto',
              maxHeight: '200px',
              overflowY: 'auto',
               background: '#fff',
              display: 'flex',
              padding: '10px',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              borderRadius: '5px',
              marginTop: '10px',
            }}
          >
            {question.text}
          </div>
          <div className="space"></div>
            </div>
          {/* Botões Ver Respostas e Responder */}
          <div style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
            <button
              className="btn_tertiary"
              onClick={toggleAnswersVisibility}
            >
              {areAnswersVisible ? 'Fechar' : 'Ver respostas'}
            </button>
            <button
              className="btn_secondary"
              onClick={onRespond}
            >
              Responder
            </button>
          </div>

          {/* Renderização dos comentários */}
          {areAnswersVisible && question.comments ? question.comments.map((comment) => (
            <div className="container-father" key={comment.id}>
              <div
                className="name-text"
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                  padding: "10px 10px",
                  borderRadius: "10px",
                  width: "96%",
                  background: "#e5e7eb",
                }}
              >
                <div
                  className="avarta-resposta"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>{/* <ImageIcon /> */}</Avatar>
                  </ListItemAvatar>
                  <div className="p_bold">{comment.user.name}</div>
                </div>

                <div
                  className="p_normal"
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "200px",
                    overflowY: "auto",
                    background: "#fff",
                    display: "flex",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {comment.text}
                </div>

                <div
                  className="resposta-pergunta"
                  style={{ marginTop: "20px" }}
                >
                  {/* Conteúdo adicional pode ser inserido aqui */}
                </div>
              </div>
            </div>
          )) : null}

        </div>
      </ListItem>
    </List>
  );
};

const QuestionCollaboration: React.FC = () => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentQuestion, setCurrentQuestion] = useState<Question | null>(null);
  const [responseText, setResponseText] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    quizQuestionService
      .getAllUnansweredQuestions()
      .then((response) => {
        setQuestions(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError('Erro ao carregar perguntas não respondidas.');
        setLoading(false);
      });
  }, []);

  const handleRespondClick = (question: Question) => {
    setCurrentQuestion(question);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentQuestion(null);
  };

  const handleSubmitResponse = () => {
    // Log para verificar o estado atual da pergunta
    console.log('Pergunta atual:', currentQuestion);
  
    // Verifica se há uma pergunta selecionada e se o texto da resposta foi preenchido
    if (!currentQuestion || !responseText) {
      swal('Erro', 'Por favor, escreva uma resposta antes de enviar.', 'error');
      return;
    }
  
    // Log para verificar o ID da pergunta
    console.log('ID da pergunta:', currentQuestion.id);
  
    // Cria o objeto de dados para o comentário
    const commentData = {
      questionId: Number(currentQuestion.id), // Certifique-se de que é um número
      text: responseText,
    };
  
    // Log para verificar os dados a serem enviados
    console.log('Dados enviados:', commentData);
  
    // Envia o comentário para o serviço responsável
    quizQuestionService
      .saveCommentAfterQuestion(commentData)
      .then(() => {
        swal('Sucesso', 'Resposta enviada com sucesso. Obrigado por colaborar!', 'success');
        handleCloseModal();
      })
      .catch((error) => {
        swal('Erro', 'Não foi possível enviar sua resposta. Tente novamente.', 'error');
        console.error('Erro ao enviar resposta:', error); // Loga o erro no console para debug
      });
  };
  

  if (loading) return <div>Carregando...</div>;
  if (error) return <div>{error}</div>;

  

  return (
    <div>
      <nav className="breadcrumb-talent" style={{ marginTop: 0 }}>
        <ol style={{ display: "flex", alignItems: "center" }}>
          <li style={{ marginLeft: "10px" }}>
            <FaHome style={{ marginRight: "5px" }} />
            <a className="p_normal" href="/cursos">
              Início
            </a>
          </li>
          <li>
            <a className="p_normal" href="">Colaboração</a>
          </li>
        </ol>
      </nav>
      <div className="">
        <div className="space"></div>
        <h3 className="title" style={{ marginLeft: '25%' }}>
          Colabore com quem está aprendendo
        </h3>
        <Divider style={{ width: '100%', color: '#FFFFFF' }} />

        {questions.length > 0 ? (
          questions.map((question) => (
            <div style={{ width: '100%' }} key={question.id}>
              <QuestionDetails
                question={question}
                onRespond={() => handleRespondClick(question)}
              />
            </div>
          ))
        ) : (
          <p>Não há perguntas não respondidas.</p>
        )}
      </div>

      {/* Modal para responder a pergunta */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 1500, // Aumentei a largura do modal para 600 pixels
            bgcolor: 'background.paper',
            border: '2px solid rgba(0, 0, 0, 0.3)',
            borderRadius: '20px', // Bordas mais arredondadas
            boxShadow: 24,
            p: 6,
          }}
        >
          <h2 className="title">Colabore com quem está aprendendo</h2>
          <div className="space"></div>
          {currentQuestion && (
            <div>
              <h3>{currentQuestion.title}</h3>
              <p>{currentQuestion.text}</p>
              <TextField
                label="Sua resposta"
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                margin="normal"
                onChange={(e) => setResponseText(e.target.value)}  // Captura a resposta
              />
              <div style={{ marginTop: '20px' }}>
                <button
                  
                 className='btn_secondary'
                  onClick={handleSubmitResponse}
                >
                  Enviar Resposta
                </button>
                <button
                  className='btn_tertiary'
                  onClick={handleCloseModal}
                  style={{ marginLeft: '10px' }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default QuestionCollaboration;
