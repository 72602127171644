import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth";
import "./index.scss";
import { useContext } from "react";
import { Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import SettingsIcon from "@mui/icons-material/Settings";
import { IoIosArrowDown } from "react-icons/io";
import Avatar from '@mui/material/Avatar';
import usersService from "../../services/UsersService";
import swal from "sweetalert";
import { FaAngleDoubleDown } from "react-icons/fa";

const Header = () => {
  const {
    signed,
    markAsSigned,
    markAsNotSigned,
    getName,
    storeName,
    storeRole,
    role,
    getPhotoProfile
  } = useContext(AuthContext);
  const [photoProfile, setPhotoProfile] = useState<string | null>(getPhotoProfile() || null);
  const navigate = useNavigate();

  const mySkills = () => {
    navigate("/minhas-skills");
    handleMenuClose();
  };

  const myMetrics = () => {
    navigate("/minhas-metrics");
    handleMenuClose();
  };

  const studentCollaboration = () => {
    navigate("/student-collaboration");
    handleMenuClose();
  };

  const listInterviews = () => {
    navigate("/list-interviews");
    handleMenuClose();
  };

  const listClasses = () => {
    navigate("/list-classes");
    handleMenuClose();
  };

  const myCourses = () => {
    navigate("/cursos");
    handleMenuClose();
  };

  const changePassword = () => {
    navigate("/alterar-senha");
    handleMenuClose();
  };

  const myProfileStudent = () => {
    navigate("/profile-student");
    handleMenuClose();
  };

  const linkStyles = {
    textDecoration: "none",
    transition: "transform 0s", // Isso remove o efeito de hover do cursor
  };

  const linkHoverStyles = {
    color: "inherit !important", // Sobrescreve qualquer outra cor de hover
  };

  const logout = () => {
    localStorage.removeItem("TOKEN");
    markAsNotSigned();
    navigate("/");
    handleMenuClose();
  };

  const [isLoading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { getRole } = useContext(AuthContext);
  const [name, setName] = useState<string | null>(getName());

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {getRole() === "student" && (
        <>
          <MenuItem onClick={myProfileStudent}>Meu perfil</MenuItem>
          <MenuItem onClick={myCourses}>Meus cursos</MenuItem>
          <MenuItem onClick={mySkills}>Minhas skills</MenuItem>
          <MenuItem onClick={myMetrics}>Minhas Métricas</MenuItem>
          <MenuItem onClick={studentCollaboration}>Colaboração</MenuItem>
          <MenuItem onClick={listInterviews}>Minhas entrevistas</MenuItem>
        </>
      )}

      {role !== "student" && (
        <Link
          className="link-company"
          to={role === "company" ? "/consumedCredits" : "/login"}
          style={linkStyles}
        >
          <MenuItem>Minha Assinatura</MenuItem>
        </Link>
      )}
      <MenuItem onClick={changePassword}>Alterar senha</MenuItem>
      <MenuItem onClick={logout}>Sair</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <SettingsIcon />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const login = (values: any) => {
    let data = {
      username: values.email,
      password: values.password,
    };

    usersService
      .login(data)
      .then((response) => {
        setLoading(false);
        markAsSigned();
        storeName(response.data.name);
        storeRole(response.data.role);
        if (response.data.role === "student") {
          navigate("/cursos");
        } else {
          navigate("/consumedCredits");
        }
      })
      .catch((error) => {
        setLoading(false);
        swal({
          title: "Erro",
          text: 'A senha ou e-mail está incorreto. Se não se lembra da sua senha, redefina clicando em "esqueci minha senha"',
          icon: "error",
        });
      });
  };

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(name: string) {
    if (name.indexOf(" ") >= 0){
      name = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    }else{
        name = `${name.substring(0,1)}`    
    }
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 40,
        height: 40,
        fontSize: 12,
      },
      children: `${name}`,
    };
  }

  return (
    <>
      <header>
        <Link
          to={
            role === "student"
              ? "/cursos"
              : role === "company"
                ? "/consumedCredits"
                : "/login"
          }
        >
          <img
            className="icon-talent-header"
            src="/coin.png"
            width="45"
            height="45"
            alt="logoFatecoins"
          />
          <h4 className="header_logo">FATECOINS TALENT</h4>
        </Link>

        <div
          className="options"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div style={{ display: "flex" }}>
            {getPhotoProfile() != null && getPhotoProfile() != "" ? (
              <img
                src={`https://nyc3.digitaloceanspaces.com/test-fatecoins-bucket/${getPhotoProfile()}`}
                alt="Profile"
                style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              />
            ) : (
              <Avatar {...stringAvatar(name!)}
              />
            )}
            <div className="usuario">
              <h4 className="parag_bold" style={{ margin: 0 }}>
                {getName()}
              </h4>
            </div>
          </div>
        </div>

        <Box sx={{ display: { md: "flex" } }}>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <FaAngleDoubleDown
              className="icon-menu-header icon-bounce"
              style={{ color: "#FFFFFF", marginLeft: "8px" }}
            />
          </IconButton>
        </Box>

        {renderMobileMenu}
        {renderMenu}
      </header>
    </>
  );
};

export default Header;
