import { Button, CircularProgress } from "@material-ui/core";
import Slider from "@mui/material/Slider";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { FaGithub, FaLinkedin, FaSearch, FaYoutube } from "react-icons/fa";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

import "./index.scss";
import { useContext, useEffect, useState } from "react";
import skillsService from "../../services/SkillsService";
import studentsService from "../../services/StudentsService";
import swal from "sweetalert";
import ISkillFilter from "../../interfaces/ISkillFilter";
import ISkillsEntity from "../../interfaces/ISkillsEntity";
import IStudentSkill from "../../interfaces/IStudentSkill";
import SubscriptionContext from "../../context/Subscription";
import { planIdEnum } from "../../enums/plan-id.enum";
import interviewService from "../../services/InterviewService";
import { IUserProfileImage } from "../../interfaces/IUser";

const SeekTalent = () => {
  const [selectedSkills, setSelectedSkills] = useState<ISkillFilter[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const { subscription } = useContext(SubscriptionContext);
  const [skillsEntity, setSkillsEntity] = useState<ISkillsEntity[]>([]);
  const [students, setStudents] = useState<IStudentSkill[]>([]);
  const [users, setUsers] = useState<IUserProfileImage[]>([]);

  const addSkill = (item: ISkillFilter) => {
    if (!selectedSkills.some((skill) => skill.id === item.id)) {
      setSelectedSkills([...selectedSkills, item]);
    }
  };

  const toogleSkill = (item: ISkillsEntity) => {
    let foundSkill = selectedSkills.find((skill) => skill.id === item.id);
    if (foundSkill) {
      deleteSkill(item.id);
    } else {
      addSkill({
        id: item.id,
        name: item.name,
        value: 50,
      });
    }
  };

  const deleteSkill = (id: number) => {
    const newList = selectedSkills.filter((skill) => skill.id !== id);
    setSelectedSkills(newList);
  };

  const fetchSkills = () => {
    skillsService.getAll().then((response) => {
      setSkillsEntity(response.data);
    });
  };

  const search = () => {
    setLoading(true);
    studentsService
      .getAllBySkills(selectedSkills)
      .then((response) => {

        let studentsList = response.data;

        // Ordenar os alunos pela soma total das skills selecionadas
        studentsList = studentsList.sort((a: IStudentSkill, b: IStudentSkill) => {
          const totalSkillsA = selectedSkills.reduce((acc, skill) => {
            const skillValue = Number(a[skill.name]) || 0;
            return acc + skillValue;
          }, 0);

          const totalSkillsB = selectedSkills.reduce((acc, skill) => {
            const skillValue = Number(b[skill.name]) || 0;
            return acc + skillValue;
          }, 0);

          return totalSkillsB - totalSkillsA; // Ordem decrescente
        });

        setStudents(studentsList);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        swal({
          icon: "error",
          title: "Houve um erro ao buscar os talentos. Tente mais 1 vez, por favor.",
        });
      });
  };


  const sendEmail = (idStudent: number, name: string) => {
    setSubmitting(true);
    const data = { idStudent };
    swal({
      title: `Você realmente deseja enviar um email para ${name}?`,
      buttons: ["não", "sim"],
    }).then((response) => {
      if (response) {
        interviewService
          .create(data)
          .then(() => {
            setSubmitting(false);
            swal({
              icon: "success",
              title: "Email enviado com sucesso!",
            });
          })
          .catch(() => {
            setSubmitting(false);
            swal({
              icon: "error",
              title: "Houve um erro ao enviar seu email. Tente novamente, por favor!",
            });
          });
      } else {
        setSubmitting(false);
      }
    });
  };

  useEffect(() => {
    fetchSkills();
  }, []);

  useEffect(() => {
    if (selectedSkills.length === 0) {
      setStudents([]);
    }
  }, [selectedSkills]);

  const StaticProgressBar = (props: { name: string; value: string }) => {
    const formattedValue = parseFloat(props.value);

    return (
      <div className="progress-bar-container">
        <div className="progress-bar-tooltip" style={{ left: formattedValue + "%" }}>
          {formattedValue}%
        </div>
        <div className="progress-bar-foreground" style={{ maxWidth: formattedValue + "%" }}></div>
      </div>
    );
  };


  const getFirstLastName = (name: string) => {
    let names = name.split(" ");
    if (names.length > 1) {
      return names[0] + " " + names[names.length - 1];
    } else {
      return names[0];
    }
  };

  return (
    <div className="container-search" style={{ marginTop: "60px" }}>
      {isLoading && (
        <img
          src="/assets/images/loading_II.gif"
          className="icon-loading"
          alt="carregando"
        />
      )}

      <>
        <div
          className="seek"
          style={{
            marginTop: "10px",
            width: "1140px",
            margin: "0 auto",
            padding: "20px",
            background: "#F9FAFB",
            border: "1px solid #E9E9E9",
          }}
        >
          <div
            className="assinatura"
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h1 className="title" style={{ color: "#413C69", fontSize: "40px" }}>
              Encontre talentos para sua empresa
            </h1>
            <hr style={{ border: "2px solid #fcb900", width: "50%" }} />
          </div>
          <div className="filter-skills">
            <div className="title-skills-container">
              <h2>Selecione as habilidades que você procura</h2>
            </div>
            <div className="skills-container">
              {skillsEntity.map((skill) => (
                <div className="skills-card" key={skill.id}>
                  <Button
                    className="button-add"
                    style={{
                      backgroundColor:
                        selectedSkills.find((item) => item.id === skill.id) != null
                          ? "#059669"
                          : "#ddeeff",
                      color:
                        selectedSkills.find((item) => item.id === skill.id) != null
                          ? "#fff"
                          : "#000",
                    }}
                    onClick={() => toogleSkill(skill)}
                  >
                    {skill.name}
                  </Button>
                </div>
              ))}
            </div>
          </div>
          {selectedSkills.length > 0 && (
            <div className="filter-ranges">
              <div className="filter">
                <h2>Selecione o nível de cada habilidade</h2>
              </div>
              {selectedSkills.map((skill) => (
                <div className="search" style={{ alignItems: "center" }} key={skill.id}>
                  <div className="icon">
                    <DeleteIcon
                      style={{ cursor: "pointer", fontSize: "40px" }}
                      onClick={() => deleteSkill(skill.id)}
                    />
                  </div>
                  <div className="skills-search">
                    <p>{skill.name}</p>
                  </div>
                  <Slider
                    value={skill.value}
                    onChange={(e, value) =>
                      setSelectedSkills(
                        selectedSkills.map((item) => {
                          if (skill.id === item.id) {
                            return {
                              id: item.id,
                              name: item.name,
                              value: typeof value === "number" ? value : value[0],
                            };
                          } else {
                            return item;
                          }
                        })
                      )
                    }
                    aria-label="Default"
                    valueLabelDisplay="auto"
                  />
                </div>
              ))}
              {!isLoading && (
                <button style={{ marginTop: "10px" }} className="btn_secondary" onClick={search}>
                  Buscar talentos
                </button>
              )}
              {isLoading && (
                <div className="loading">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando...</span>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="search-results">
            {students.length === 0 && selectedSkills.length > 0 && <h2>Nenhum resultado para a busca</h2>}
            {students.length > 0 && selectedSkills.length > 0 && (
              <>

                {students.map((student: IStudentSkill) => (
                  <div className="row-result" key={student.studentId}>
                    <div className="avatar">
                      {student.profileImage && student.profileImage != null && student.profileImage.length > 0 ? (
                        <img
                          src={`https://nyc3.digitaloceanspaces.com/test-fatecoins-bucket/${student.profileImage}`}
                          alt="Profile"
                          style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                        />
                      ) : (
                        <Avatar />
                      )}
                    </div>
                    <div className="student-skills">
                      <h3 style={{ display: 'flex', alignItems: 'center' }}>
                        {getFirstLastName(student.name)}
                        {student.youtube_address && (
                          <a
                            href={student.youtube_address} target="_blank" rel="noopener noreferrer">
                            <FaYoutube
                              style={{
                                fontSize: "30px",
                                color: "red",
                                marginLeft: "30px",
                                cursor: "pointer"
                              }}
                            />
                          </a>
                        )}
                      </h3>
                      {student.linkedin && (
                        <a
                          href={student.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <FaLinkedin style={{ color: "#0077b5" }} /> Linkedin
                        </a>
                      )}
                    </div>
                    <div className="row-skills">
                      {selectedSkills
                        .slice() // Faz uma cópia do array para evitar mutações no array original
                        .sort((a, b) => {
                          // Ordena os skills com base no valor associado no estudante
                          const valueA = Number(student[a.name]) || 0;
                          const valueB = Number(student[b.name]) || 0;
                          return valueB - valueA; // Ordem decrescente
                        })
                        .map((skill) => (
                          <div className="skill-user" key={skill.id}>
                            <div className="skills-language">
                              <span>{skill.name}</span>
                            </div>
                            <div className="skill-progress-bar" style={{ width: '95%' }}>
                              <StaticProgressBar
                                name={skill.name}
                                value={String(Number(student[skill.name]) * 100)}
                              />
                            </div>
                          </div>
                        ))}

                    </div>
                    <button
                      className="btn_primary"
                      style={{ marginTop: "10px" }}
                      onClick={() => sendEmail(student.studentId, student.name)}
                    >
                      {isSubmitting ? <CircularProgress color={"inherit"} /> : "Quero Entrevistar"}
                    </button>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </>

    </div>
  );
};

export default SeekTalent;
