import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth";

const PrivateRoute = ({ element }: { element: JSX.Element }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { signed } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loader = () => {
    if (!signed) {
      return navigate("/login", {
        state: {
          path: location.pathname,
        },
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loader();
  }, []);

  return <div>{!isLoading && element}</div>;
};

export default PrivateRoute;
